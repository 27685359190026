import { MenuEntry } from '@pancakeswap/uikit'
import { ContextApi } from 'contexts/Localization/types'

const config: (t: ContextApi['t']) => MenuEntry[] = (t) => [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: 'https://app.zcore.network',
  },
  /*
  {
    label: 'Exchange',
    icon: 'ExchangeIcon',
    href: 'https://exchange.zcore.network/#/swap',
  },
  {
    label: 'Liquidity',
    icon: 'LiquidityIcon',
    href: 'https://exchange.zcore.network/#/pool',
  },  
  */
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://exchange.zcore.network/#/swap',
      },
      {
        label: 'Liquidity',
        href: 'https://exchange.zcore.network/#/pool',
      },   
    ],
  },     
  {
    label: t('Farms'),
    icon: 'FarmIcon',
    href: 'https://app.zcore.network/farms',
  },
  /*
  {
    label: 'Vaults',
    icon: 'VaultsIcon',
    href: 'https://vaults.zcore.network',
    status: {
      text: 'AUTO',
      color: 'success',
    },    
  },  
  */
  {
    label: 'Vaults',
    icon: 'VaultsIcon',
    items: [
      {
        label: 'version 1.0',
        href: '/zefi',
      },
      {
        label: 'version 2.0',
        href: 'https://vaults.zcore.farm/',
      },   
    ],
  },   
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: 'https://app.zcore.network/pools',
  },
  {
    label: 'Lottery',
    icon: 'TicketIcon',
    href: 'https://zefi.zcore.network/lottery',
  },
  {
    label: 'IFO',
    icon: 'IfoIcon',
    href: 'https://app.zcore.network/ifo',
  },  
  /*
  {
    label: 'Buy ZEFI',
    icon: 'MoreIcon',
    items: [
      {
        label: 'ZCore Dex',
        href: 'https://exchange.zcore.network/#/swap?inputCurrency=ETH&outputCurrency=0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
      },
      {
        label: 'Pancake Swap',
        href: 'https://exchange.pancakeswap.finance/#/swap?inputCurrency=ETH&outputCurrency=0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
      },
      {
        label: 'Cointiger',
        href: 'https://www.cointiger.com/en-us/#/trade_center?coin=zefi_usdt',
      },
    ],
  },

  {
    label: 'Info',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Price Chart',
        href: 'https://www.dextools.io/app/pancakeswap/pair-explorer/0x09B0123e36A2b0A5b95474455E437e8861a6C61E',
      },
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/zcore-finance/',
      },
      {
        label: 'DappRadar',
        href: 'https://dappradar.com/binance-smart-chain/defi/zcore-finance',
      },
      {
        label: 'BscScan',
        href: 'https://bscscan.com/address/0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
      },      
      {
        label: 'Github',
        href: 'https://github.com/zcore-coin/',
      },
    ],
  },
  */

  {
    label: 'Buy ZEFI',
    icon: 'MoreIcon',
    items: [
      {
        label: 'ZCore Dex',
        href: 'https://exchange.zcore.network/#/swap?inputCurrency=ETH&outputCurrency=0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
      },
      {
        label: '1inch',
        href: 'https://app.1inch.io/#/56/swap/BNB/ZEFI',
      },      
      {
        label: 'Pancake Swap',
        href: 'https://exchange.pancakeswap.finance/#/swap?inputCurrency=ETH&outputCurrency=0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
      },
      {
        label: 'Cointiger',
        href: 'https://www.cointiger.com/en-us/#/trade_center?coin=zefi_usdt',
      },
    ],
  },

  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Price Chart',
        href: 'https://www.dextools.io/app/pancakeswap/pair-explorer/0x09B0123e36A2b0A5b95474455E437e8861a6C61E',
      },
      {
        label: 'CoinMarketCap',
        href: 'https://coinmarketcap.com/currencies/zcore-finance/',
      },
      {
        label: 'DappRadar',
        href: 'https://dappradar.com/binance-smart-chain/defi/zcore-finance',
      },
      {
        label: 'BscScan',
        href: 'https://bscscan.com/address/0x0288D3E353fE2299F11eA2c2e1696b4A648eCC07',
      },      
      {
        label: 'Github',
        href: 'https://github.com/zcore-coin/',
      },
      {
        label: 'Portfolio Watcher',
        href: 'https://pacoca.io/',
      },
      {
        label: 'Vfat Tools',
        href: 'https://vfat.tools/bsc/zcore/',
      },
      {
        label: 'Add to Metamask',
        href: 'https://bit.ly/3sSDdrB',
      },      
    ],
  },  
  /*
  {
    label: 'Tools',
    icon: 'MoreIcon',
    items: [
      {
        label: 'Vfat Tools',
        href: 'https://vfat.tools/bsc/zcore/',
      },
      {
        label: 'Add to Metamask',
        href: 'https://bit.ly/3sSDdrB',
      },
    ],
  },
  */
  {
    label: 'ZCore Network',
    icon: 'ZCoreIcon',
    href: 'https://zcore.network',
  },
  {
    label: 'Credit Card',
    icon: 'CCIcon',
    href: 'https://finance.zcore.network',
  },
  {
    label: 'Audit By Certik',
    icon: 'AuditIcon',
    href: 'https://certik.org/projects/zcore',
  },
  {
    label: 'D-U-N-S Number',
    icon: 'AuditIcon',
    href: 'https://profiles.dunsregistered.com/DunsRegisteredProfileAnywhere.aspx?key1=3141951&PaArea=1',
  },  
  {
    label: 'Docs',
    icon: 'GitbookIcon',
    href: 'https://docs.zcore.fi/',
  },    
]

export default config
